<template lang="">
    <footer class="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div class="d-flex col-6 align-items-center">
            <div>
                <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none">
                    <img src="@/assets/serg.png" alt="" srcset="" style="height: 64px;">
                </a>
            </div>
            <div class="mb-3 mb-md-0 text-muted flex-grow-1">© 2023 Sheffield Hallam University: Sports Engineering Research Group</div>
        </div>

        <div class="text-muted d-flex">
            <div>
                <div>Assembled by Shaun Barratt</div>
                <div style="font-size: 0.8em;">Model provided by MoveNet</div>
            </div>
            <div>
                <a class="text-muted ms-3" target="_blank" href="https://www.linkedin.com/in/shaun-barratt/" style="font-size: 2em;"><i class="bi-linkedin"></i></a>
            </div>
        </div>
    </footer>
</template>
<script>
export default {

}
</script>
<style lang="">
    
</style>