<template>
  <router-view/>
  <Footer></Footer>
</template>

<style lang="scss">
body {
  // overflow-y: hidden;
}
</style>

<script>
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer,
  },
};

</script>
