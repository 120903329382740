<template>
  <div class="home">
    <div class="container mt-3">
      
      <div class="display-6">SHU: SERG Pose Model Test Harness</div>
      <hr/>
      <div class="row">
        <div class="col col-md-6">
          <div>
            <div class="d-flex">
            </div>
            <div class="h6" v-if="isLoading">
              Loading
            </div>
            <div class="video-container mb-3">
              <div class="canvas-wrapper">
                <canvas id="output"></canvas>
                <video id="video" playsinline style="
                  -webkit-transform: scaleX(-1);
                  transform: scaleX(-1);
                  visibility: hidden;
                  width: 400px;
                  height: 400px;
                  ">
                </video>
              </div>
              <div id="scatter-gl-container"></div>
            </div>
            
            <div class="row">
              <div class="col-4">
                <img src="@/assets/pose-serg-qr.png" style="max-height: 150px;" alt="">
              </div>
              <div class="col-8">
                
                <div>
                  <div class="fs-5 display-6">Model Options</div>
                  <hr/>

                  <div class="row">
                    <label class="col-sm-2 col-form-label my-auto">Camera</label>
                    <div class="col-sm-10">
                      <select class="form-control form-control-sm" name="" id="" v-model="selectedCamera" @change="changeDevice">
                        <option value="">Select Camera</option>
                        <option :value="device.id" v-for="device in cameras">{{ device.label }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label my-auto">Model</label>
                    <div class="col-sm-10">
                      <select class="form-control form-control-sm" name="" id="" v-model="selectedModel" @change="changeModel">
                        <option value="">Select Model</option>
                        <option :value="model.id" v-for="model in models">{{ model.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-sm-2 col-form-label my-auto">Actions</label>
                    <div class="col-sm-10 d-flex">
                      <div class="me-2">
                        <button class="btn btn-primary" @click="start" v-if="!isRecording">Start Recording</button>
                        <button class="btn btn-danger" @click="stop" v-if="isRecording">Stop Recording</button>
                      </div>
                      <div>
                        <!-- Toggle camera -->
                        <button class="btn btn-primary" @click="showCamera = !showCamera">Toggle Camera</button>
                      </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col col-md-6">
          <div class="ms-2 w-100">
            <div>
              <div class="display-6 fs-2">Results</div>
              <hr/>

              <div class="row border border-5 p-2" v-if="poses.length == 0">
                <div class="d-flex justify-content-center">
                  <div class="h6">No Pose Detected</div>
                </div>
              </div>

              <div class="row" v-else v-for="(pose, i) in poses.sort((a, b) => a.id - b.id )" :key="pose.id">
                <div class=" border border-5 p-2 mt-2" :style="{ 'border-color': COLOR_PALETTE[poses[i]?.id % 20] + '!important',  }">
                <!-- <div class="h6 mb-0">Pose {{i+1}}</div> -->
                  <div class="d-flex justify-content-center">
                    <div class="h6" :style="{ color: COLOR_PALETTE[poses[i].id % 20] }">Pose {{ poses[i].id }}</div>
                  </div>
                    
                  <div class="row">
                    <div class="text-center col">
                      <img src="@/assets/knee_left.jpg" alt="" srcset="" :style="{ 'max-height': '80px' }">
                      <div class="fw-bold fs-3">{{ find_pose_joint_angle(poses[i], 'left_hip', 'left_knee', 'left_ankle')?.toFixed(0).padStart(3, '0') }}°</div>
                    </div>

                    <div class="text-center col">
                      <img src="@/assets/arm_left.jpg" alt="" srcset="" :style="{ 'max-height': '80px' }">
                      <div class="fw-bold fs-3">{{ find_pose_joint_angle(poses[i], 'left_shoulder', 'left_elbow', 'left_wrist')?.toFixed(0).padStart(3, '0')  }}°</div>
                    </div>

                    <div class="text-center col">
                      <img src="@/assets/shoulder_left.jpg" alt="" srcset="" :style="{ 'max-height': '80px' }">
                      <div class="fw-bold fs-3">{{ find_pose_joint_angle(poses[i], 'left_hip', 'left_shoulder', 'left_elbow')?.toFixed(0).padStart(3, '0')  }}°</div>
                    </div>

                    <div class="text-center col">
                      <img src="@/assets/shoulder_right.jpg" alt="" srcset="" :style="{ 'max-height': '80px' }">
                      <div class="fw-bold fs-3">{{ find_pose_joint_angle(poses[i], 'right_hip', 'right_shoulder', 'right_elbow')?.toFixed(0).padStart(3, '0')  }}°</div>
                    </div>

                    <div class="text-center col">
                      <img src="@/assets/arm_right.jpg" alt="" srcset="" :style="{ 'max-height': '80px' }">
                      <div class="fw-bold fs-3">{{ find_pose_joint_angle(poses[i], 'right_shoulder', 'right_elbow', 'right_wrist')?.toFixed(0).padStart(3, '0')  }}°</div>
                    </div>
                    
                    <div class="text-center col">
                      <img src="@/assets/knee_right.jpg" alt="" srcset="" :style="{ 'max-height': '80px' }">
                      <div class="fw-bold fs-3">{{ find_pose_joint_angle(poses[i], 'right_hip', 'right_knee', 'right_ankle')?.toFixed(0).padStart(3, '0')  }}°</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { toRaw } from 'vue'; 

import '@tensorflow/tfjs-backend-webgl';
import * as mpPose from '@mediapipe/pose';

import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);

import * as posedetection from '@tensorflow-models/pose-detection';

import {Camera} from '@/camera';
const COLOR_PALETTE = [
  '#abdbe3', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4', '#aaffc3',
  '#9a6324', '#000075', '#f58231', '#4363d8', '#ffd8b1', '#dcbeff', '#808000',
  '#ffe119', '#911eb4', '#bfef45', '#f032e6', '#3cb44b', '#a9a9a9'
];

export default {
  data() {
    return {
      models: [
        { id: '1', name: "MoveNet: MultiPose",  model: posedetection.SupportedModels.MoveNet, options: { modelType: posedetection.movenet.modelType.MULTIPOSE_LIGHTNING } },
        { id: '2', name: "MoveNet: SinglePose", model: posedetection.SupportedModels.MoveNet, options: { modelType: posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING } },
        { id: '3', name: "MoveNet: SinglePose (Thunder)", model: posedetection.SupportedModels.MoveNet, options: { modelType: posedetection.movenet.modelType.SINGLEPOSE_THUNDER } },
      ],
      selectedModel: '1',
      cameraConfig: {
        targetFPS: 30,
        sizeOption: '640 X 480',
        // sizeOption: '360 X 270'
      },
      cameras: [],
      selectedCamera: null,
      camera: null,
      detector: null,
      renderAnimationId: null,
      isFacingUs: false,
      poses: [],

      isRecording: false,
      showCamera: true,
      mediaRecorder: null,
      recordedChunks: [],

      previousRecordings: [],
      rotationCounts: -1,
      athleteAngle: 0,
      poseHistoryArray: [],

      skipFrames: 20,
      skipFramesCounter: 0,

      poseHistory: {},

      isLoading: true,
      COLOR_PALETTE,
    };
  },
  components: {
  },
  unmounted() {
    if (this.camera) {
      // this.camera.dispose();
    }
    if (this.detector) {
      this.detector.dispose();
    }

  },
  mounted() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      console.log("Devices", devices)
      let videoDevices = devices.filter((device) => device.kind === 'videoinput');
      this.cameras = videoDevices.map((device) => {
        return {
          id: device.deviceId,
          label: device.label,
        };
      });

      console.log("Cameras", this.cameras)
      this.selectedCamera = this.cameras[0].id;
    });
    this.setup();
  },
  methods: {
    async changeModel() {
      this.setup();
    },
    async changeDevice() {
      this.cameraConfig.deviceId = this.selectedCamera;
      this.isFacingUs = !this.isFacingUs;
      await this.setup();
    },
    async setup() {
      this.isLoading = true;
      if (this.renderAnimationId) {
        window.cancelAnimationFrame(this.renderAnimationId);
      }

      if (this.camera) {
        // this.camera.dispose();
        delete this.camera
      }

      if (this.detector) {
        this.detector.dispose();
        delete this.detector
      }
      
      this.camera = await Camera.setupCamera(this.cameraConfig);
      this.detector = toRaw(await this.createDetector());
      this.renderAnimationId = window.requestAnimationFrame(this.renderPrediction);

      // Setup the recorder
      if (!this.mediaRecorder) {
        var theCanvasElementToRecord = document.querySelector('#output');            
        var stream = theCanvasElementToRecord.captureStream(30); 
        var options = { mimeType: 'video/webm;codecs=vp9' };

        this.mediaRecorder = new MediaRecorder(stream, options);   
        this.mediaRecorder.ondataavailable = this.mediaRecorderData
      }

      this.isLoading = false;
    },
    async renderPrediction() {
      await this.renderResult();
      this.renderAnimationId = window.requestAnimationFrame(this.renderPrediction);
    },
    async predictResults() {

    },
    async renderResult() {
      this.skipFramesCounter++; 

      let detector = toRaw(this.detector);

      if (this.camera.video.readyState < 2) {
        await new Promise((resolve) => {
          this.camera.video.onloadeddata = () => {
            resolve(video);
          };
        });
      }

      // Detector can be null if initialization failed (for example when loading
      // from a URL that does not exist).
      let poses;

      if (detector != null) {
        // Detectors can throw errors, for example when using custom URLs that
        // contain a model that doesn't provide the expected output.
        try {
          poses = await detector.estimatePoses(
              this.camera.video,
              { maxPoses: 1, flipHorizontal: true});

          // Filter the poses 
          poses = poses.filter(x => x.score > 0.2);

          this.poses = poses;
        } catch (error) {
          detector.dispose();
          this.detector = null;
          console.error(error);
          // alert(error);
        }
      }



      this.camera.clearCtx();
      // this.camera.drawCtx();
      // console.log("Drawn")
      // This uses the poseId to draw the same color for the same pose.
      // COLOR_PALETTE[poseId % 20] 

      if (this.showCamera) {
        this.camera.drawCtx();
      } else {
        // draw a background for the poses to show up
        // this.camera.drawCtx({ width: 400, height: 400, x: 0, y: 0, color: 'white' });
        this.camera.ctx.fillStyle = 'white';
        this.camera.ctx.fillRect(0, 0, 1000, 1000);
      }

      this.camera.drawResults(poses);
      
      
      // this.processPoses();
    },
    processPoses() {
      // Do our analysis
      if (!this.poses || this.poses.length < 0) return;
      if (!this.poses[0] || !this.poses[0].keypoints) return;
      let keypoints = this.poses[0].keypoints;
      const left_hip = keypoints.find(x => x.name == 'left_hip');
      const right_hip = keypoints.find(x => x.name == 'right_hip');
      const left_shoulder = keypoints.find(x => x.name == 'left_shoulder');
      const right_shoulder = keypoints.find(x => x.name == 'right_shoulder');
      const neck_base = { x: (left_shoulder.x + right_shoulder.x) / 2, y: (left_shoulder.y + right_shoulder.y) / 2 }
      const pelvis = { x: (left_hip.x + left_hip.y) / 2, y: (left_hip.x + left_hip.y) / 2 };
      

      let athleteAngle = this.radians_to_degrees(this.find_vertical(neck_base, right_shoulder))
      this.athleteAngle = athleteAngle;
      // If the athlete is horizontal, we need to compare in the Y direction?
      let isNowFacingUs = left_hip.x > right_hip.x;

      if (isNowFacingUs != this.isFacingUs) {
        this.rotationCounts++;
      }
      this.isFacingUs = isNowFacingUs;
      // this.bodyAngle = // Asuming the camera is angled correctly are they flat or vertical
    },
    find_vertical(A, B) {
      let dX = Math.abs(A.x - B.x)
      let dY = Math.abs(A.y - B.y)
      return Math.atan2(dY, dX);
    },
    find_angle(A, B, C) {
      // In radians
      var AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
      var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
      var AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
      return Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
    },
    find_pose_vertical(pose) {

    },
    find_pose_joint_angle(pose, joint1, joint2, joint3) {
      if (!pose) return;
      let keypoints = pose.keypoints;
      
      let A = keypoints.find(x => x.name == joint1);
      let B = keypoints.find(x => x.name == joint2);
      let C = keypoints.find(x => x.name == joint3);
      
      let ang = this.radians_to_degrees(this.find_angle(A, B, C));
      // Return an averaged value
      return ang;
    },
    radians_to_degrees(radians)
    {
      var pi = Math.PI;
      return radians * (180/pi);
    },
    start() {
      if (this.isRecording) return;

      this.mediaRecorder.start();
      this.isRecording = true;
      this.rotationCounts = 0;
    },
    mediaRecorderData(event) {
      console.log("data-available");
      
      // if (event.data.size <= 0) return;

      // this.recordedChunks.push();

      const a = document.createElement('a')
      a.href = URL.createObjectURL(event.data)
      a.download = 'recording.webm'
      a.click()
      this.isRecording = false;
    },
    mediaRecorderStopped() {
    },
    stop() {
      this.mediaRecorder.stop();
      // this.isRecording = false;
      // Save?
      // Dispose the old?

    },
    
    async createDetector() {

      try { 
        // Can we store this locally? Maybe using a custom URL?
        // This has to match params.js until it is refactored out.
        // return toRaw(await posedetection.createDetector(posedetection.SupportedModels.MoveNet, { modelType: posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING }));
        // return toRaw(await posedetection.createDetector(posedetection.SupportedModels.MoveNet, { modelType: posedetection.movenet.modelType.MULTIPOSE_LIGHTNING }));
        const { model, options } = this.models.find(x => x.id == this.selectedModel);



        return toRaw(await posedetection.createDetector(model, options));
      } catch (e) {
        this.error = e.message;
        console.error("Unable to create pose detector");
        console.error(e);
      }
    }
  }
}
</script>
